import useTranslation from '../../hooks/useTranslation';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import useResponsive from '../../hooks/useResponsive';
import { useTheme } from 'next-themes';
import { DARK_THEME_NAME } from '../../tools/constants';
import style from './subheader.style';
import { Link } from 'components/utils/Link';
import useSubheaderMenu from '../../hooks/useSubheaderMenu';
import useUserCashback from '../../hooks/useUserCashback';
import { useRouterWithLang } from 'hooks/useRouterWithLang';

function SubHeader() {
  const { TRANSLATIONS } = useTranslation();
  const router = useRouterWithLang();
  const { isMobile } = useResponsive();
  const { resolvedTheme } = useTheme();
  const user = useSelector((state) => state?.user);
  const { userCashback } = useUserCashback();
  const totalGain = user?.withdrawableEcredit;
  const productPath = router.pathname === '/product';
  const homePath = router.pathname === '/';
  const showCashbackpage = productPath || homePath;

  const { menuItems } = useSubheaderMenu();

  const isSelected = useCallback(
    (value) =>
      Object.entries(router.query)
        .map((entry) => entry?.[1])
        .includes(value),
    [router.query]
  );

  return (
    <div className={'subheader'}>
      {!isMobile && (
        <nav className={'subheader__submenu'}>
          <div className={'subheader__submenu-wrap'}>
            <ul className={'subheader__submenu-wrap-list'}>
              {menuItems.map((tag, index) => (
                <Link href={tag.to} key={index}>
                  <div className={'item'}>
                    <li
                      className={`${
                        isSelected(tag?.label)
                          ? 'subheader__submenu-selected'
                          : ''
                      }`}
                      key={tag?.label}
                    >
                      {tag.label}
                    </li>
                  </div>
                </Link>
              ))}
            </ul>
          </div>
        </nav>
      )}
      {isMobile && showCashbackpage && false && (
        <>
          {!!userCashback && (
            <nav
              className={'subheader__submenu'}
              style={{ padding: '4px 16px' }}
            >
              <div className={'section-cashback d-f fd-c ai-c jc-c g-10'}>
                <div className={'d-f ai-c jc-sb g-10'}>
                  <div className={'d-f ai-c g-5'}>
                    {resolvedTheme === DARK_THEME_NAME ? (
                      <img
                        src="/images/Icon-ecredit-dark.svg"
                        height={40}
                        alt={'Icon-ecredit-dark'}
                      />
                    ) : (
                      <img
                        src="/images/ecredit/Icon-ecredit.svg"
                        height={40}
                        alt={'Icon-ecredit'}
                      />
                    )}
                    <p>
                      {TRANSLATIONS.header.subheader.youEarn}{' '}
                      <span style={{ fontSize: 15, fontWeight: 800 }}>
                        {totalGain?.toFixed(2)} €
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </nav>
          )}
        </>
      )}
      <style jsx>{style}</style>
    </div>
  );
}

export default SubHeader;
